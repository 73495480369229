import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { useRecoilValue } from "recoil";
import providerState from "../../state/provider";
import useCopy from "../../hooks/useCopy";
import Arrow from "../../assets/Arrow";
import { close } from "../../utils";

const Wrapper = styled.div`
  padding: 50px 20px;
  background: linear-gradient(
    180deg,
    rgba(44, 45, 48, 0.5) 0%,
    rgba(196, 196, 196, 0) 100%
  );
  @media (min-width: 900px) {
    padding: 20px 20px 43px;
    background: linear-gradient(
      180deg,
      #2c2d30 0%,
      rgba(196, 196, 196, 0) 60.94%
    );
  }
`;

const ProviderInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Photo = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Name = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1;
  padding-bottom: 10px;
`;

const BackArrowWrapper = styled.div`
  padding-right: 5px;
`;

const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.default};
  border: 0;
  height: 26px;
  border-radius: 50px;
  padding: 0 8px;
  cursor: pointer;
`;

const Header = () => {
  const { name, photoUrl } = useRecoilValue(providerState);
  const [titleText, backText] = useCopy([
    "header.providerTitle",
    "header.back",
  ]);
  const title = swapCopyVariables(titleText, { NAME: name });

  return (
    <Wrapper>
      <ProviderInfo>
        <Photo src={photoUrl}></Photo>
        <DetailsWrapper>
          <Name>{title}</Name>
          <BackButton onClick={close}>
            <BackArrowWrapper>
              <Arrow direction="left" />
            </BackArrowWrapper>
            {backText}
          </BackButton>
        </DetailsWrapper>
      </ProviderInfo>
    </Wrapper>
  );
};

export default Header;
