import styled from "styled-components";

const Outer = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.border};
  @media (min-width: 900px) {
    border-radius: 0;
  }
`;

const Inner = styled.div`
  width: ${({ width }) => width}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  transition: width 0.2s ease-in-out;
`;

const ProgressBar = ({ currentStep, totalSteps }) => {
  if (!currentStep || !totalSteps) {
    return null;
  }

  const width = (currentStep / totalSteps) * 100;

  return (
    <Outer>
      <Inner width={width}></Inner>
    </Outer>
  );
};

export default ProgressBar;
