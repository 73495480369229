import { useState, useEffect } from "react";
import styled from "styled-components";
import Unlock from "../../assets/Unlock";
import Loading from "../../assets/Loading";
import useCopy from "../../hooks/useCopy";
import { Link } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 25px 20px;
  text-align: center;
`;

const StyledInput = styled.input`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: center;
`;

const InputWrapper = styled.div`
  padding: 30px 0 10px;
  input {
    margin: 0 3px;
  }
`;

const ResendWrapper = styled.div`
  margin-top: 10px;
  height: 20px;
`;

const NumberInput = ({ value, onChange, index, ...rest }) => {
  return (
    <StyledInput
      type="text"
      maxlength="1"
      value={value}
      onChange={({ target }) => onChange(index, target.value)}
      id={`passcodeInput${index}`}
      {...rest}
    />
  );
};

const NumberBox = ({ onSubmit, loading }) => {
  const [triedValue, setTriedValue] = useState(null);
  const [passcode, setPasscode] = useState({
    n0: "",
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
  });

  useEffect(() => {
    const currentValue = Object.values(passcode).join("");
    if (currentValue.length === 6 && triedValue !== currentValue) {
      onSubmit(currentValue);
      setTriedValue(currentValue);
    }
  }, [passcode, onSubmit, triedValue]);

  const handleChange = (index, value) => {
    if (!/^\d{1}$/.test(value)) {
      return;
    }
    const key = `n${index}`;
    setPasscode(
      p => ({
        ...p,
        [key]: value,
      }),
      () => {},
    );
    if (value === "") {
      return;
    }
    if (index === 5) {
      return;
    }
    const currentValue = Object.values(passcode).join("");
    if (currentValue.length > 5) {
      return;
    }
    const nextIndex = index + 1;
    const nextElement = document.getElementById(`passcodeInput${nextIndex}`);
    if (nextElement) {
      nextElement.focus();
    }
  };
  const handlePaste = event => {
    event.clipboardData.items[0].getAsString(s => {
      if (/^\d{6}$/.test(s)) {
        const pastedCode = {
          n0: s[0],
          n1: s[1],
          n2: s[2],
          n3: s[3],
          n4: s[4],
          n5: s[5],
        };
        setPasscode(pastedCode);
        onSubmit(s);
      }
    });
  };
  return (
    <InputWrapper>
      {loading ? (
        <Loading />
      ) : (
        [0, 1, 2, 3, 4, 5].map(i => {
          const key = `n${i}`;
          return (
            <NumberInput
              key={key}
              index={i}
              onPaste={handlePaste}
              onChange={handleChange}
              value={passcode[key]}
              disabled={loading}
            />
          );
        })
      )}
    </InputWrapper>
  );
};

const Passcode = ({ obfuscatedEmail, onSubmit, resend, loading }) => {
  const [resendingCode, setResendingCode] = useState(false);
  const [enterPasscodeTitle, enterPasscodeBody, resendText, resendLinkText] =
    useCopy([
      "steps.confirmAccount.enterPasscodeTitle",
      "steps.confirmAccount.enterPasscodeBody",
      "steps.confirmAccount.resend",
      "steps.confirmAccount.resendLink",
    ]);
  const handleResend = async () => {
    setResendingCode(true);
    await resend();
    setResendingCode(false);
  };
  const ResendLink = () => <Link onClick={handleResend}>{resendLinkText}</Link>;
  return (
    <Wrapper>
      <Unlock />
      <h2>{enterPasscodeTitle}</h2>
      <div>
        {swapCopyVariables(enterPasscodeBody, { EMAIL: obfuscatedEmail })}
      </div>
      <NumberBox onSubmit={onSubmit} />
      <ResendWrapper>
        {resendingCode ? (
          <Loading />
        ) : (
          swapCopyVariables(resendText, { RESEND: <ResendLink /> })
        )}
      </ResendWrapper>
    </Wrapper>
  );
};

export default Passcode;
