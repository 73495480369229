import { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { H2, Link, Button, TextInput } from "@sussex/react-kit/elements";
import Loading from "../../assets/Loading";
import Confirm from "../Confirm";
import { ErrorBox } from "../InfoBox";

import userPool from "../../cognito";
import userState from "../../state/user";
import useCopy from "../../hooks/useCopy";

const Container = styled.div`
  > * {
    margin: 8px 0;
  }
`;

const ErrorText = styled.div`
  margin: 0 15px;
`;

const Heading = styled(H2)`
  margin: 16px 0;
`;

const LinkText = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const SkipText = styled(LinkText)`
  text-align: center;
  width: 100%;
`;

const Submit = styled(Button)`
  width: 100%;
  height: 56px;
`;

const SignIn = ({ next, canSkip = true, back }) => {
  const [
    titleText,
    emailText,
    passwordText,
    signinErrorText,
    submitText,
    forgotText,
    skipText,
    backText,
  ] = useCopy([
    "steps.signIn.title",
    "steps.signIn.email",
    "steps.signIn.password",
    "steps.signIn.error",
    "steps.signIn.signIn",
    "steps.signIn.forgotPassword",
    "steps.signIn.skip",
    "steps.signIn.back",
  ]);
  const setUserState = useSetRecoilState(userState);
  const [confirmUser, setConfirmUser] = useState(false);
  const [user, setUser] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const signIn = () => {
    setLoading(true);
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
      Storage: window.sessionStorage,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        setLoading(false);
        let name = "",
          phone = "";
        cognitoUser.getUserAttributes((err, attrResult) => {
          if (err) {
            console.error("Error getting user attributes", err);
          } else {
            attrResult.forEach(a => {
              if (a.Name === "name") {
                name = a.Value;
              }
              if (a.Name === "phone_number") {
                phone = a.Value;
              }
            });
          }
          setUserState({
            signedIn: true,
            verified: true,
            username: email,
            name: name,
            email: email,
            phone: phone,
          });
        });
        next();
        return;
      },
      onFailure: err => {
        console.log({ err });
        setLoading(false);
        if (err.message === "User is not confirmed.") {
          setUserState({ signedIn: true, verified: false, username: email });
          setUser(cognitoUser);
          setConfirmUser(true);
          return;
        }
        if (err.message === "Incorrect username or password.") {
          setLoginError(signinErrorText);
        }
        console.log(err.message || JSON.stringify(err));
      },
    });
  };
  if (confirmUser) {
    return <Confirm user={user} next={next} />;
  }

  return (
    <Container>
      <Heading>{titleText}</Heading>
      {loginError && (
        <ErrorBox>
          <ErrorText>{loginError}</ErrorText>
        </ErrorBox>
      )}
      <TextInput
        label={emailText}
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <TextInput
        label={passwordText}
        value={password}
        type="password"
        onChange={({ target }) => setPassword(target.value)}
      />
      <LinkText
        onClick={() => {
          console.log("haven't implemented yet. will in future ticket");
        }}
      >
        {forgotText}
      </LinkText>
      <Submit
        type="submit"
        disabled={password.length < 8 || email === ""}
        onClick={signIn}
      >
        {loading ? <Loading /> : submitText}
      </Submit>
      {canSkip ? (
        <SkipText onClick={next}>{skipText}</SkipText>
      ) : (
        <SkipText onClick={back}>{backText}</SkipText>
      )}
    </Container>
  );
};

export default SignIn;
