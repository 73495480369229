import { atom } from "recoil";

const user = atom({
  key: "User", // unique ID (with respect to other atoms/selectors)
  default: {
    username: null,
    signedIn: false,
    verified: false,
    name: "",
    phone: "",
    email: "",
  }, // default value (aka initial value)
});

export default user;
