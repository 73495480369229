import { createPortal } from "react-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { Button } from "@sussex/react-kit/elements";
import { summaryState } from "./state";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  background-color: white;
  padding: 24px;
  background: #fff;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  width: calc(100% - 48px);
  @media (min-width: 900px) {
    border-radius: 0;
    width: 377px;
  }
  @media (min-width: 1200px) {
    width: 502px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 8px;
  @media (min-width: 900px) {
    gap: 10px;
  }
`;

const Btn = styled(Button)`
  width: 100%;
  height: 56px;
`;

const Buttons = ({ next, cancel, disabled }) => {
  const summary = useRecoilValue(summaryState);
  const [nextText, saveText, cancelText] = useCopy([
    "steps.next",
    "steps.save",
    "steps.cancel",
  ]);

  if (summary) {
    return (
      <>
        <Btn onClick={cancel} variant="white" type="button">
          {cancelText}
        </Btn>
        <Btn onClick={next} disabled={disabled} type="submit">
          {saveText}
        </Btn>
      </>
    );
  }
  return (
    <Btn onClick={next} disabled={disabled} type="submit">
      {nextText}
    </Btn>
  );
};

const Footer = ({ footerRef, next, cancel, disabled, children }) => {
  return (
    footerRef.current &&
    createPortal(
      <Wrapper>
        {children || (
          <ButtonWrapper>
            <Buttons next={next} cancel={cancel} disabled={disabled} />
          </ButtonWrapper>
        )}
      </Wrapper>,
      footerRef.current,
    )
  );
};

export default Footer;
