import { useTheme } from "styled-components";

const Unlock = ({ color }) => {
  const theme = useTheme();
  const stroke = color || theme.colors.primaryBlue;

  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66634 11.6667V6.33333C6.66634 4.91885 7.22824 3.56229 8.22844 2.5621C9.22863 1.5619 10.5852 1 11.9997 1C13.4142 1 14.7707 1.5619 15.7709 2.5621C16.7711 3.56229 17.333 4.91885 17.333 6.33333M11.9997 17V19.6667M3.99967 25H19.9997C20.7069 25 21.3852 24.719 21.8853 24.219C22.3854 23.7189 22.6663 23.0406 22.6663 22.3333V14.3333C22.6663 13.6261 22.3854 12.9478 21.8853 12.4477C21.3852 11.9476 20.7069 11.6667 19.9997 11.6667H3.99967C3.29243 11.6667 2.61415 11.9476 2.11406 12.4477C1.61396 12.9478 1.33301 13.6261 1.33301 14.3333V22.3333C1.33301 23.0406 1.61396 23.7189 2.11406 24.219C2.61415 24.719 3.29243 25 3.99967 25Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Unlock;
