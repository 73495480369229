const isIframe = window.self !== window.top;
const isPopup = !!window.opener;
export const postMessageToParent = msg => {
  let opener = null;
  if (isIframe) {
    opener = window.parent;
  }
  if (isPopup) {
    opener = window.opener;
  }
  if (!opener) {
    return;
  }
  opener.postMessage(msg, "*");
};

export const close = () => {
  console.log("TODO: close");
};
