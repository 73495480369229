import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Link, Button } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import { formState } from "../state";
import providerState from "../../../state/provider";
import useCopy from "../../../hooks/useCopy";
import Pill from "../../Pill";
import Arrow from "../../../assets/Arrow.js";
import SignIn from "../../SignIn";
import X from "../../../assets/X";
import Prompt from "../Prompt";

export const formTimeState = selector({
  key: "FormData.Time",
  get: ({ get }) => get(formState).time,
  set: ({ set }, newValue) => set(formState, s => ({ ...s, time: newValue })),
});

const timeSlots = {
  morning: [8, 9, 10, 11],
  afternoon: [12, 13, 14, 15, 16],
  evening: [17, 18, 19, 20],
};

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  border-radius: 10px;
`;

const Break = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  margin: 16px 0;
`;

const XWrapper = styled.div`
  vertical-align: top;
  margin-left: 10px;
  display: inline-block;
`;

const AccountTextWrapper = styled.div`
  margin-top: 16px;
  line-height: 22px;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const AccountTextHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const AccountTextBody = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
`;

const NextButton = styled(Button)`
  width: 100%;
  height: 56px;
`;

const TimeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 24px;
`;

const PillWrapper = styled.div`
  * {
    margin: 16px 0;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Date = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.colors.border : theme.colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Prev = ({ onClick, disabled }) => {
  const theme = useTheme();

  return (
    <ArrowWrapper onClick={disabled ? null : onClick} disabled={disabled}>
      <Arrow
        direction="left"
        color={disabled ? theme.colors.border : theme.colors.dark}
      />
    </ArrowWrapper>
  );
};

const Next = ({ onClick }) => {
  const theme = useTheme();

  return (
    <ArrowWrapper onClick={onClick}>
      <Arrow direction="right" color={theme.colors.dark} />
    </ArrowWrapper>
  );
};

const TimeSlot = styled(Pill)`
  text-transform: lowercase;
`;

const NoTimeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 24px;
  text-align: center;
`;

const NoTimesAvailable = () => {
  const [noneAvailable] = useCopy(["steps.time.noneAvailable"]);
  return <NoTimeWrapper>{noneAvailable}</NoTimeWrapper>;
};

const Confirm = ({ next }) => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [time, setTime] = useRecoilState(formTimeState);
  const [signInText, nextText, haveAccountText, signInWithAccountText] =
    useCopy([
      "steps.signIn.signInLink",
      "steps.next",
      "steps.time.haveAccount",
      "steps.time.signInWithAccount",
    ]);

  const cancel = () => {
    //navigate back
    setTime("");
  };
  const start = new window.Date(time);
  const stop = new window.Date(time + 60 * 60 * 1000);

  const SignInLink = () => (
    <Link onClick={() => setShowSignIn(true)}>{signInText}</Link>
  );

  const Next = () => (
    <div>
      <NextButton onClick={next} type="submit">
        {nextText}
      </NextButton>
      <AccountTextWrapper>
        <AccountTextHeader>{haveAccountText}</AccountTextHeader>
        <AccountTextBody>
          {swapCopyVariables(signInWithAccountText, {
            LINK: <SignInLink />,
          })}
        </AccountTextBody>
      </AccountTextWrapper>
    </div>
  );

  return (
    <Wrapper>
      <Pill selected={true} onClick={cancel}>
        {start.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
        })}
        {", "}
        {start.toLocaleTimeString("en-US", { timeStyle: "short" })}
        {" - "}
        {stop.toLocaleTimeString("en-US", { timeStyle: "short" })}
        <XWrapper>
          <X />
        </XWrapper>
      </Pill>
      <Break />
      {showSignIn ? <SignIn next={next} /> : <Next />}
    </Wrapper>
  );
};

const TimeBox = ({ time = null, onSelect }) => {
  const startDate = time ? new window.Date(time) : new window.Date();
  const [date, setDate] = useState(startDate);
  const { availability } = useRecoilValue(providerState);
  const dayOfWeek = days[date.getDay()];
  const availableTimes = availability[dayOfWeek];
  const sections = Object.keys(timeSlots).filter(k => availableTimes[k]);
  const timesForDate = sections.reduce(
    (acc, s) => [...acc, ...timeSlots[s]],
    [],
  );
  const prevDay = new window.Date(date.getTime() - 60 * 60 * 24 * 1000);
  const midnight = new window.Date();
  midnight.setHours(0, 0, 0, 0);
  const previousDisabled = prevDay < midnight;
  const handlePrevDay = () => {
    setDate(prevDay);
  };
  const nextDay = () =>
    setDate(new window.Date(date.getTime() + 60 * 60 * 24 * 1000));

  return (
    <TimeWrapper>
      <Heading>
        <Prev onClick={handlePrevDay} disabled={previousDisabled} />
        <Date>
          {date.toLocaleDateString("en-US", {
            weekday: "long",
            day: "numeric",
            month: "long",
          })}
        </Date>
        <Next onClick={nextDay} />
      </Heading>
      <PillWrapper>
        {timesForDate.length ? (
          timesForDate.map(d => {
            const startTime = new window.Date(date);
            startTime.setHours(d, 0, 0, 0);
            const stopTime = new window.Date(startTime);
            stopTime.setHours(d + 1, 0, 0, 0);
            return (
              <TimeSlot
                selected={startTime.getTime() === time}
                onClick={() => onSelect(startTime.getTime())}
                key={`${startTime}${stopTime}`}
              >
                {startTime.toLocaleTimeString("en-US", { timeStyle: "short" })}{" "}
                - {stopTime.toLocaleTimeString("en-US", { timeStyle: "short" })}
              </TimeSlot>
            );
          })
        ) : (
          <NoTimesAvailable />
        )}
      </PillWrapper>
    </TimeWrapper>
  );
};

const Time = ({ next }) => {
  const [time, setTime] = useRecoilState(formTimeState);
  const handleTimeSelect = selectedTime => {
    setTime(selectedTime);
  };
  const [prompt] = useCopy(["steps.time.prompt"]);
  const showTimePicker = time === "";

  return (
    <div>
      <Prompt bold>{prompt}</Prompt>
      {showTimePicker ? (
        <TimeBox time={time} onSelect={handleTimeSelect} />
      ) : (
        <Confirm next={next} />
      )}
    </div>
  );
};

export default Time;
