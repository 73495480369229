import styled from "styled-components";
import PTLogo from "../../assets/PTLogo";

const Wrapper = styled.footer`
  display: none;
  @media (min-width: 900px) {
    display: block;
    height: 122px;
    position: relative;
  }
`;

const Gradient = styled.div`
  display: none;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, #2c2d30 0%, rgba(196, 196, 196, 0) 100%);
  opacity: 0.5;
  transform: rotate(-180deg);
`;

const LogoWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

const Footer = () => {
  return (
    <Wrapper>
      <Gradient />
      <LogoWrapper>
        <PTLogo />
      </LogoWrapper>
    </Wrapper>
  );
};

export default Footer;
