import { useTheme } from "styled-components";

const X = ({ color }) => {
  const theme = useTheme();
  const fill = color || theme.colors.primaryBlue;
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0C4.1 0 0.5 3.6 0.5 8C0.5 12.4 4.1 16 8.5 16C12.9 16 16.5 12.4 16.5 8C16.5 3.6 12.9 0 8.5 0ZM12 10.1L10.6 11.5L8.5 9.4L6.4 11.5L5 10.1L7.1 8L5 5.9L6.4 4.5L8.5 6.6L10.6 4.5L12 5.9L9.9 8L12 10.1Z"
        fill={fill}
      />
    </svg>
  );
};

export default X;
