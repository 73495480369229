import { useTheme } from "styled-components";

const TherapyNeedsIcon = ({ width = 24, height = 24, color }) => {
  const theme = useTheme();
  const fill = color || theme.colors.default;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z"
        fill={fill}
      />
      <path
        d="M9.85303 6.55977C11.833 5.68477 14.395 5.78877 15.27 7.20477C16.145 8.62077 15.541 10.2678 14.041 11.5388C12.541 12.8098 12 13.4998 12 14.4998"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TherapyNeedsIcon;
