import styled from "styled-components";
import { useState } from "react";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { useRecoilState } from "recoil";
import useCopy from "../../hooks/useCopy";
import userState from "../../state/user";
import Passcode from "../Passcode";
import { InfoBox, ErrorBox } from "../InfoBox";

const obfuscateEmail = email => {
  if (!email) {
    return;
  }
  const parts = email.split("@");
  const name = parts[0];
  return (
    name[0] +
    "*".repeat(name.length - 2) +
    name[name.length - 1] +
    "@" +
    parts[1]
  );
};

const BoxWrapper = styled.div`
  margin-bottom: 20px;
`;

const Confirm = ({ next, user }) => {
  const [errorSendingCodeText, codeResentText] = useCopy([
    "steps.confirm.errorResending",
    "steps.confirm.passcodeResent",
  ]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [codeResent, setCodeResent] = useState(null);
  const [userData, setUserData] = useRecoilState(userState);
  const resend = () =>
    new Promise(resolve => {
      setCodeResent(false);
      user.resendConfirmationCode((err, result) => {
        if (err) {
          setError(errorSendingCodeText);
          console.log(err);
          return;
        }
        setCodeResent(true);
        resolve(result);
      });
    });

  const confirm = code => {
    setLoading(true);
    setCodeResent(false);
    setError(null);
    user.confirmRegistration(code, true, (err, result) => {
      setLoading(false);
      if (!err) {
        setUserData({ ...userData, verified: true });
        next();
        return;
      }
      if (err.code === "CodeMismatchException") {
        setError("Mismatched code");

        return;
      }
      console.log(err.message || JSON.stringify(err));
    });
  };
  const obfuscatedEmail = obfuscateEmail(userData.username);

  return (
    <>
      {codeResent && (
        <BoxWrapper>
          <InfoBox>
            {swapCopyVariables(codeResentText, {
              EMAIL: obfuscatedEmail,
            })}
          </InfoBox>
        </BoxWrapper>
      )}
      {error && (
        <BoxWrapper>
          <ErrorBox>{error}</ErrorBox>
        </BoxWrapper>
      )}
      <Passcode
        obfuscatedEmail={obfuscatedEmail}
        onSubmit={confirm}
        resend={resend}
        loading={loading}
      />
    </>
  );
};

export default Confirm;
