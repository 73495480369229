import { atom } from "recoil";

export const STEPS = {
  TIME: "Time",
  THERAPY_NEEDS: "TherapyNeeds",
  PAYMENT_METHOD: "PaymentMethod",
  COMMENTS: "Comments",
  SIGN_UP: "SignUp",
  SUMMARY: "Summary",
  COMPLETE: "Complete",
};

export const summaryState = atom({
  key: "FormSummary", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const stepState = atom({
  key: "FormStep", // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});

export const formState = atom({
  key: "FormData", // unique ID (with respect to other atoms/selectors)
  default: {
    time: "",
    issues: [],
    otherIssue: "",
    paymentMethods: "",
    insurances: [],
    otherInsurance: "",
    message: "",
  }, // default value (aka initial value)
});
