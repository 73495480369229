import styled, { withTheme } from "styled-components";
import { CloseButton, H2, H3 } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import ProgressBar from "../ProgressBar";
import useCopy from "../../hooks/useCopy";
import { close } from "../../utils";

const ContentWrapper = styled.div`
  display: flex;
  padding: 6px 0 24px;
  @media (min-width: 900px) {
    justify-content: space-between;
  }
`;

const TextWrapper = styled.div``;

const CloseWrapper = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
  }
`;

const StepNumber = styled(H3)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.secondary};
`;

const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.default};
  line-height: 1;
`;

const ProgressWrapper = styled.div`
  padding-bottom: 24px;
  @media (min-width: 900px) {
    border-top: ${({ theme, showBorder }) =>
      showBorder ? `1px solid ${theme.colors.border}` : 0};
    width: calc(100% + 50px);
    margin-left: -25px;
  }
`;

const Header = ({ currentStep, totalSteps, title, theme }) => {
  const showProgressBar = !!currentStep;
  const [stepText] = useCopy(["steps.stepNumber"]);
  const stepNumber = swapCopyVariables(stepText, { NUMBER: currentStep });

  return (
    <>
      <ContentWrapper>
        <TextWrapper>
          {currentStep && <StepNumber>{stepNumber}</StepNumber>}
          <Title>{title}</Title>
        </TextWrapper>
        <CloseWrapper>
          <CloseButton
            close={close}
            width={"14px"}
            height={"14px"}
            fill={theme.colors.default}
            id="stepform-close"
            type="button"
          />
        </CloseWrapper>
      </ContentWrapper>
      <ProgressWrapper showBorder={!showProgressBar}>
        {showProgressBar && (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        )}
      </ProgressWrapper>
    </>
  );
};

export default withTheme(Header);
