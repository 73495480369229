import AWS from "aws-sdk";
import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
} from "@aws-crypto/client-browser";
import userPool from "./cognito";
import { fromBase64, toBase64 } from "@aws-sdk/util-base64-browser";

const region = process.env.REACT_APP_REGION;
const idp = process.env.REACT_APP_IDP;
const cognitoIDP = process.env.REACT_APP_COGNITO_IDENTITY;
const generatorKeyId = process.env.REACT_APP_KEY_ID;

const { encrypt, decrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT,
);

export const getIDToken = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser == null) {
    return;
  }
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
      }
      if (!session.isValid()) {
        reject("session is not valid");
      }

      const idToken = session.getIdToken().getJwtToken();
      resolve(idToken);
    });
  });
};

export const decryptText = async hashedEncrypted => {
  const encrypted = fromBase64(hashedEncrypted);
  const credentials = await getIDPPermissions();
  if (!credentials) {
    throw new Error("unable to get credentials");
  }
  const clientProvider = getClient(KMS, { credentials });
  const keyring = new KmsKeyringBrowser({ generatorKeyId, clientProvider });
  const decrypted = await decrypt(keyring, encrypted);
  const dec = new TextDecoder();
  const decoded = dec.decode(decrypted.plaintext);
  return decoded;
};

export const encryptText = async message => {
  const credentials = await getIDPPermissions();
  if (!credentials) {
    console.log("unable to get credentials");
    throw new Error("unable to get credentials");
  }
  const enc = new TextEncoder();
  const bytes = enc.encode(message);
  const clientProvider = getClient(KMS, { credentials });
  const keyring = new KmsKeyringBrowser({ generatorKeyId, clientProvider });
  const encrypted = await encrypt(keyring, bytes);
  const hashedEncrypted = toBase64(encrypted.result);
  return hashedEncrypted;
};

export const getIDPPermissions = async () => {
  const idToken = await getIDToken();
  if (!idToken) {
    throw new Error("unable to get credentials");
  }

  AWS.config.region = region;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: idp,
    Logins: {
      [cognitoIDP]: idToken,
    },
  });
  return new Promise((resolve, reject) => {
    AWS.config.credentials.get((err, result) => {
      if (err) {
        console.log("unable to get credentials");
        reject(err);
      }
      resolve({
        secretAccessKey: AWS.config.credentials.secretAccessKey,
        accessKeyId: AWS.config.credentials.accessKeyId,
        sessionToken: AWS.config.credentials.sessionToken,
      });
    });
  });
};
