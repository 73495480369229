import { useState } from "react";
import { TextInput } from "@sussex/react-kit/elements";
import styled from "styled-components";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import Pill from "../../Pill";
import { formState } from "../state";
import providerState from "../../../state/provider";
import useCopy from "../../../hooks/useCopy";
import Prompt from "../Prompt";
import Footer from "../Footer";

export const formPaymentState = selector({
  key: "FormData.Payment",
  get: ({ get }) => get(formState).paymentMethod,
  set: ({ set }, newValue) =>
    set(formState, s => ({ ...s, paymentMethod: newValue })),
});

export const formInsuranceState = selector({
  key: "FormData.Insurances",
  get: ({ get }) => get(formState).insurances,
  set: ({ set }, newValue) =>
    set(formState, s => ({ ...s, insurances: newValue })),
});

export const formOtherInsuranceState = selector({
  key: "FormData.OtherInsurances",
  get: ({ get }) => get(formState).otherInsurance,
  set: ({ set }, newValue) =>
    set(formState, s => ({ ...s, otherInsurance: newValue })),
});

const PillWrapper = styled.div`
  * {
    margin: 16px 0;
  }
`;

const InsuranceWrapper = styled.div`
  margin-top: 24px;
`;

const OtherInput = styled(TextInput)`
  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`;

const PaymentMethod = ({ next, footerRef }) => {
  const [
    howToPayPrompt,
    cashText,
    insuranceText,
    selectPrompt,
    otherText,
    otherPlaceholderText,
    outOfNetworkText,
  ] = useCopy([
    "steps.payment.prompt",
    "steps.payment.cash",
    "steps.payment.insurance",
    "steps.payment.select",
    "steps.other",
    "steps.otherPlaceholder",
    "steps.payment.outOfNetwork",
  ]);
  const [howToPay, setHowToPay] = useRecoilState(formPaymentState);
  const [selectedInsurances, setInsurances] =
    useRecoilState(formInsuranceState);
  const [otherInsurance, setOtherInsurance] = useRecoilState(
    formOtherInsuranceState,
  );
  const { outOfNetwork, insurances } = useRecoilValue(providerState);
  const [localHowToPay, setLocalHowToPay] = useState(howToPay);
  const [localSelectedInsurances, setLocalInsurances] =
    useState(selectedInsurances);
  const insuranceList = [...insurances];
  if (outOfNetwork) {
    insuranceList.push(outOfNetworkText);
  }
  const [showOther, setShowOther] = useState(!!otherInsurance);

  const isInsurance = localHowToPay === "insurance";
  const toggleInsurance = s => {
    const shouldRemove = localSelectedInsurances.includes(s);
    if (shouldRemove) {
      setLocalInsurances(localSelectedInsurances.filter(x => x !== s));
      return;
    }
    setLocalInsurances(localSelectedInsurances.concat([s]));
  };
  const setOther = value => {
    if (value.length > 50) {
      return;
    }
    setOtherInsurance(value);
  };
  const nextEnabled = () => {
    if (localHowToPay === "cash") {
      return true;
    }
    if (localHowToPay === "insurance") {
      return localSelectedInsurances.length > 0;
    }
  };
  const handleNext = () => {
    setHowToPay(localHowToPay);
    setInsurances(localSelectedInsurances);
    next();
  };
  const handleCancel = () => {
    next();
  };

  return (
    <>
      <Prompt bold>{howToPayPrompt}</Prompt>
      <PillWrapper>
        <Pill
          selected={localHowToPay === "cash"}
          onClick={() => {
            setLocalHowToPay("cash");
            setLocalInsurances([]);
          }}
        >
          {cashText}
        </Pill>
        <Pill
          selected={localHowToPay === "insurance"}
          onClick={() => setLocalHowToPay("insurance")}
        >
          {insuranceText}
        </Pill>
      </PillWrapper>
      {isInsurance && (
        <InsuranceWrapper>
          <Prompt>{selectPrompt}</Prompt>
          <PillWrapper>
            {insuranceList.map(s => (
              <Pill
                key={s}
                selected={localSelectedInsurances.includes(s)}
                onClick={() => toggleInsurance(s)}
              >
                {s}
              </Pill>
            ))}
            {!outOfNetwork && (
              <Pill
                selected={showOther}
                onClick={() => {
                  if (showOther) {
                    setOther("");
                  }
                  setShowOther(!showOther);
                }}
              >
                {otherText}
              </Pill>
            )}
            {showOther && (
              <OtherInput
                value={otherInsurance}
                placeholder={otherPlaceholderText}
                maxlength="50"
                onChange={({ target }) => setOther(target.value)}
              />
            )}
          </PillWrapper>
        </InsuranceWrapper>
      )}
      <Footer
        disabled={!nextEnabled()}
        next={handleNext}
        cancel={handleCancel}
        footerRef={footerRef}
      />
    </>
  );
};

export default PaymentMethod;
