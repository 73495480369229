import { useTheme } from "styled-components";

const ContactInfoIcon = ({ width = 24, height = 20, color }) => {
  const theme = useTheme();
  const fill = color || theme.colors.default;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9H19"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13H19"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 15C4 13.343 5.343 12 7 12H9C10.657 12 12 13.343 12 15H4Z"
        fill={fill}
      />
      <path
        d="M19 1C19 2.105 18.105 3 17 3C15.895 3 15 2.105 15 1H9C9 2.105 8.105 3 7 3C5.895 3 5 2.105 5 1H1V19H23V1H19Z"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z"
        fill={fill}
      />
    </svg>
  );
};

export default ContactInfoIcon;
