import styled, { withTheme } from "styled-components";
import Panel from "../Panel";
import Spinner from "../../assets/Loading";

const Wrapper = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Loading = ({ theme }) => {
  const {
    colors: { default: defaultColor, border },
  } = theme;

  return (
    <Wrapper>
      <Panel>
        <SpinnerWrapper>
          <Spinner size={24} color={border} backgroundColor={defaultColor} />
        </SpinnerWrapper>
      </Panel>
    </Wrapper>
  );
};

export default withTheme(Loading);
