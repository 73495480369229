import { useState, useEffect } from "react";
import useCopy from "../../hooks/useCopy";
import styled from "styled-components";
import { TextInputLabel, TextInput } from "@sussex/react-kit/elements";
import {
  getCountryCallingCode,
  parseIncompletePhoneNumber,
} from "libphonenumber-js";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountryCode = styled.div`
  width: 60px;
  input {
    text-align: center;
    padding: 0;
    border-radius: 5px 0 0 5px;
    border-right: none;
    margin: 0;
    color: ${({ theme }) => theme.colors.gray};
  }
`;
const PhoneNumber = styled.div`
  width: 100%;
  div {
    border-radius: 0 5px 5px 0;
    margin: 0;
  }
`;

const PhoneNumberInput = ({
  profileCountry = "us",
  error,
  onChange,
  value,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(
    "+" + getCountryCallingCode(profileCountry.toUpperCase()),
  );
  const [phoneText] = useCopy(["steps.signUp.phone"]);

  const handleCountryCodeChange = ({ target }) => {
    let newValue = target.value;
    const validRegex = /^\+?\d{0,3}$/;
    if (!validRegex.test(newValue)) {
      return;
    }
    if (newValue[0] !== "+") {
      newValue = "+" + newValue;
    }
    setCountryCode(newValue);
  };
  useEffect(() => {
    onChange(parseIncompletePhoneNumber(countryCode + phoneNumber));
  }, [onChange, phoneNumber, countryCode]);

  return (
    <Wrapper>
      <CountryCode>
        <TextInput
          error={error}
          value={countryCode}
          onChange={handleCountryCodeChange}
        />
      </CountryCode>
      <PhoneNumber>
        <TextInputLabel
          error={error}
          label={phoneText}
          value={value.substring(countryCode.length)}
          onChange={({ target }) => {
            setPhoneNumber(target.value);
          }}
        />
      </PhoneNumber>
    </Wrapper>
  );
};

export default PhoneNumberInput;
