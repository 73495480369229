import { selector } from "recoil";
import { close } from "../utils";

const apiBase = process.env.REACT_APP_API_URL;
const params = new URLSearchParams(window.location.search);
const profileUUID = params.get("profile_uuid");

const fetchData = async id => {
  try {
    const response = await fetch(`${apiBase}/profiles/${id}`);
    const data = await response.json();
    return data.profile;
  } catch (e) {
    console.error(e);
    close();
    return {};
  }
};

const provider = selector({
  key: "Provider", // unique ID (with respect to other atoms/selectors)
  get: () => {
    return fetchData(profileUUID);
  },
});

export default provider;
