import { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import parsePhoneNumber from "libphonenumber-js";
import { Anchor, Button } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { formState, stepState, summaryState, STEPS } from "../state";
import providerState from "../../../state/provider";
import userState from "../../../state/user";
import { getIDToken, encryptText } from "../../../idp";
import useCopy from "../../../hooks/useCopy";
import Prompt from "../Prompt";
import Footer from "../Footer";
import ContactInfoIcon from "../../../assets/ContactInfoIcon";
import CommentsIcon from "../../../assets/CommentsIcon";
import PaymentIcon from "../../../assets/PaymentIcon";
import TherapyNeedsIcon from "../../../assets/TherapyNeedsIcon";
import TimeIcon from "../../../assets/TimeIcon";
import Checkmark from "../../../assets/Checkmark";
import Loading from "../../../assets/Loading";

const url = `${process.env.REACT_APP_API_URL}/requests`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  overflow: hidden;
`;

const StepIconWrapper = styled.div`
  width: 24px;
`;

const StepBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-family: ${({ theme }) => theme.fonts.primary};
  width: 100%;
  margin: 0 20px;
  h3 {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    line-height: 1;
    margin: 0 0 10px;
  }
  & > * {
    margin: 0 0 6px;
    line-height: 1.5;
  }
  & > *:last-child {
    margin-bottom: -4px;
  }
`;

const StepEditWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StepEditButton = styled(Button)`
  min-width: 57px;
  padding: 8px 12px;
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  p {
    margin: 0 10px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SubmitButton = styled(Button)`
  height: 56px;
  width: 100%;
`;

const TOS = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.33;
  margin: 10px auto 0;
  a {
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`;

const formatTime = startTimestamp => {
  const startTime = new Date(startTimestamp);
  const stopTime = new Date(startTime);
  stopTime.setHours(startTime.getHours() + 1);
  return (
    startTime.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }) +
    " - " +
    startTime.toLocaleTimeString("en-US", { timeStyle: "short" }) +
    " - " +
    stopTime.toLocaleTimeString("en-US", {
      timeStyle: "short",
    })
  );
};

const Step = ({ icon, header, items, onEdit }) => {
  const [editText] = useCopy(["steps.summary.editButtonText"]);

  return (
    <StepWrapper>
      <StepIconWrapper>{icon()}</StepIconWrapper>
      <StepBody>
        <h3>{header}</h3>
        {items.map((val, i) => (
          <div key={i}>{val}</div>
        ))}
      </StepBody>
      {onEdit && (
        <StepEditWrapper>
          <StepEditButton onClick={onEdit}>{editText}</StepEditButton>
        </StepEditWrapper>
      )}
    </StepWrapper>
  );
};

const Summary = ({ next, footerRef }) => {
  const [loading, setLoading] = useState(false);
  const formData = useRecoilValue(formState);
  const { profileUuid, timezone } = useRecoilValue(providerState);
  const { name, email, phone } = useRecoilValue(userState);
  const setSummary = useSetRecoilState(summaryState);
  const setCurrentStep = useSetRecoilState(stepState);
  const [
    prompt,
    contactInfoHeader,
    timeHeader,
    therapyNeedsHeader,
    paymentHeader,
    commentsHeader,
    sendButtonText,
    tosText,
    tosLinkText,
    privacyPolicyLinkText,
    paymentMethodCashText,
    paymentMethodInsuranceText,
  ] = useCopy([
    "steps.summary.prompt",
    "steps.summary.contactInfoHeader",
    "steps.summary.timeHeader",
    "steps.summary.therapyNeedsHeader",
    "steps.summary.paymentHeader",
    "steps.summary.commentsHeader",
    "steps.summary.sendButtonText",
    "steps.summary.tos",
    "steps.summary.tosLink",
    "steps.summary.privacyPolicyLink",
    "steps.payment.cash",
    "steps.payment.insurance",
  ]);
  const handleClick = async () => {
    setLoading(true);
    const idToken = await getIDToken();
    // put new data
    const encrypted = await encryptText(formData.message);
    if (!encrypted) {
      return;
    }
    const date = new Date(formData.time).toISOString().substr(0, 10);
    const time = new Date(formData.time).toTimeString().substr(0, 5);
    const request = {
      profileUuid,
      date,
      time,
      timezone,
      otherIssue: formData.otherIssue,
      issues: formData.issues,
      paymentMethods: [formData.paymentMethod],
      insurances: formData.insurances,
      otherInsurance: formData.otherInsurance,
      message: encrypted,
    };
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(request),
    });
    setLoading(false);
    next();
  };

  useEffect(() => {
    setSummary(true);
  }, [setSummary]);

  return (
    <>
      <Prompt>{prompt}</Prompt>
      <Step
        icon={() => <ContactInfoIcon />}
        header={contactInfoHeader}
        items={[
          name,
          <EmailWrapper>
            <p>{email}</p>
            <Checkmark />
          </EmailWrapper>,
          parsePhoneNumber(phone)?.formatNational(),
        ]}
      />
      <Step
        icon={() => <TimeIcon />}
        header={timeHeader}
        items={[formatTime(formData.time)]}
        onEdit={() => setCurrentStep(STEPS.TIME)}
      />
      <Step
        icon={() => <TherapyNeedsIcon />}
        header={therapyNeedsHeader}
        items={
          formData.otherIssue
            ? [...formData.issues, formData.otherissue]
            : formData.issues
        }
        onEdit={() => setCurrentStep(STEPS.THERAPY_NEEDS)}
      />
      <Step
        icon={() => <PaymentIcon />}
        header={paymentHeader}
        items={[
          formData.paymentMethod === "cash"
            ? paymentMethodCashText
            : paymentMethodInsuranceText,
        ]}
        onEdit={() => setCurrentStep(STEPS.PAYMENT_METHOD)}
      />
      <Step
        icon={() => <CommentsIcon />}
        header={commentsHeader}
        items={[formData.message]}
        onEdit={() => setCurrentStep(STEPS.COMMENTS)}
      />

      <Footer footerRef={footerRef}>
        <SubmitButton onClick={handleClick} type="submit">
          {loading ? <Loading /> : sendButtonText}
        </SubmitButton>
        <TOS>
          {swapCopyVariables(tosText, {
            TOS_LINK: <Anchor href="">{tosLinkText}</Anchor>,
            PRIVACY_POLICY_LINK: (
              <Anchor href="">{privacyPolicyLinkText}</Anchor>
            ),
          })}
        </TOS>
      </Footer>
    </>
  );
};

export default Summary;
