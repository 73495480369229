import { useRecoilValue } from "recoil";
import copy from "../state/copy";

const useCopy = keys => {
  const c = useRecoilValue(copy);

  return keys.map(key => c[key] || "");
};

export default useCopy;
