import React from "react";
import AvailableBackgrounds, { DefaultBackground } from "../../backgrounds";
import styled from "styled-components";

const Styled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("/backgrounds/full/${({ background }) => background}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 900px) {
    background-size: cover;
    background-position: center center;
  }
`;

// TODO: move background stuff to react-kit
const BackgroundImage = props => {
  const background = DefaultBackground;
  return <Styled background={AvailableBackgrounds[background]} {...props} />;
};

export default BackgroundImage;
