import styled from "styled-components";

const Wrapper = styled.div`
  background-color: white;
  padding: 20px 25px;
  box-shadow: -10px 0px 15px rgba(31, 41, 55, 0.2);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: scroll;
  height: calc(100% - 40px);
  @media (min-width: 900px) {
    border-radius: 0;
    width: 375px;
  }
  @media (min-width: 1200px) {
    width: 500px;
  }
`;

const Panel = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Panel;
