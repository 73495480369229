import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL,
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
  Storage: window.sessionStorage,
};

const userPool = new CognitoUserPool(poolData);

export default userPool;
