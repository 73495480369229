import styled from "styled-components";

const Prompt = styled.div`
  margin-bottom: 24px;
  line-height: 1.4;
  font-family: ${({ theme, bold }) =>
    bold ? theme.fonts.semiBold : theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

export default Prompt;
