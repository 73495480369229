import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { isMobile } from "@sussex/react-kit/utils";

export const datadogInit = () => {
  // Initialize Datadog Logs
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOG_TOKEN,
    site: "datadoghq.com",
    service: "book-now",
    env: process.env.REACT_APP_ENVIRONMENT,
    silentMultipleInit: true,
  });

  // Initialize Datadog RUM
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_TOKEN,
    site: "datadoghq.com",
    service: "book-now",
    env: process.env.REACT_APP_ENVIRONMENT,
    silentMultipleInit: true,
    sampleRate: 100,
    resourceSampleRate: 100,
  });

  datadogRum.addRumGlobalContext(
    "device_type",
    isMobile ? "mobile" : "desktop",
  );
};

export const datadogSetPage = page => {
  datadogRum.addRumGlobalContext("page", page);

  datadogEvent({
    category: "page",
    event: "viewed",
  });
};
export const datadogEvent = ({
  category,
  event,
  feature = null,
  component = null,
  metadata = null,
}) => {
  // Event name format: evt.<category>[.<feature>].<event>
  // component is stored together with metadata in the context of the event
  let name = `evt.${category}`;
  if (feature) {
    name += `.${feature}`;
  }
  name += `.${event}`;

  const ctx = metadata ? { ...metadata } : {};
  if (component) {
    ctx.component = component;
  }

  datadogRum.addAction(name, ctx);
};
