import { useTheme } from "styled-components";

const Loading = ({ color, backgroundColor, size = 16, ...rest }) => {
  const theme = useTheme();
  const bgColor = backgroundColor || theme.colors.primaryBlue;
  const spinnerColor = color || "#FFFFFF";

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
      stroke={bgColor}
      {...rest}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(3 3)" strokeWidth="4">
          <circle cx="18" cy="18" r="18" stroke={spinnerColor} />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.85s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
};

export default Loading;
