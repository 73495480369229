import styled from "styled-components";

const Input = styled.input`
  /* Hide checkbox visually but remain accessible to screen readers. */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Label = styled.label`
  display: block;
  border-radius: 30px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.primaryBlue : theme.colors.border};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.primaryBlue : theme.colors.default};
  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.primaryBlue};
  }
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  padding: 15px;
  background-color: white;
  text-align: center;
  cursor: pointer;
`;

const Pill = ({ selected, onClick, children }) => {
  return (
    <Label selected={selected}>
      <Input type="checkbox" checked={selected} onChange={onClick} />
      {children}
    </Label>
  );
};

export default Pill;
